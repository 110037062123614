import _parser from "./lib/parser";
import _processingInstructions from "./lib/processing-instructions";
import _isValidNodeDefinitions from "./lib/is-valid-node-definitions";
import _processNodeDefinitions from "./lib/process-node-definitions";
var exports = {};
const parser = _parser;
const processingInstructions = _processingInstructions;
const isValidNodeDefinitions = _isValidNodeDefinitions;
const processNodeDefinitions = _processNodeDefinitions;
exports = {
  Parser: parser,
  ProcessingInstructions: processingInstructions,
  IsValidNodeDefinitions: isValidNodeDefinitions,
  ProcessNodeDefinitions: processNodeDefinitions
};
export default exports;
export const Parser = exports.Parser,
  ProcessingInstructions = exports.ProcessingInstructions,
  IsValidNodeDefinitions = exports.IsValidNodeDefinitions,
  ProcessNodeDefinitions = exports.ProcessNodeDefinitions;