import _utils from "./utils";
var exports = {};
const utils = _utils;

// eslint-disable-next-line max-len
// https://github.com/facebook/react/blob/15.0-stable/src/renderers/dom/shared/ReactDOMComponent.js#L457
const voidElementTags = ["area", "base", "br", "col", "embed", "hr", "img", "input", "keygen", "link", "meta", "param", "source", "track", "wbr", "menuitem", "textarea"];
function ProcessNodeDefinitions() {
  function processDefaultNode(node, children, index) {
    if (node.type === "text") {
      return node.data;
    } else if (node.type === "comment") {
      // FIXME: The following doesn't work as the generated HTML results in
      // "&lt;!--  This is a comment  --&gt;"
      // return '<!-- ' + node.data + ' -->';
      return false;
    }
    if (voidElementTags.indexOf(node.name) > -1) {
      return utils.createElement(node, index);
    } else {
      return utils.createElement(node, index, node.data, children);
    }
  }
  return {
    processDefaultNode: processDefaultNode
  };
}
exports = ProcessNodeDefinitions;
export default exports;