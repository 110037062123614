import _shouldProcessNodeDefinitions from "./should-process-node-definitions";
import _processNodeDefinitions from "./process-node-definitions";
var exports = {};
const ShouldProcessNodeDefinitions = _shouldProcessNodeDefinitions;
const ProcessNodeDefinitions = _processNodeDefinitions;
function ProcessingInstructions() {
  const processNodeDefinitions = new ProcessNodeDefinitions();
  return {
    defaultProcessingInstructions: [{
      shouldProcessNode: ShouldProcessNodeDefinitions.shouldProcessEveryNode,
      processNode: processNodeDefinitions.processDefaultNode
    }]
  };
}
;
exports = ProcessingInstructions;
export default exports;